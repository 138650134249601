import { Box, Button, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { container } from "../../../views/SocialDevelopment.styles";
import { mr10, mt35 } from "../../sharedStyles";
import ErasableTextField from "../../ErasableTextField";
import SnackBarNotification from "../../SnackBarNotification";
import canvasService from "../../../service/canvasService";
import AlertDialog from "../../AlertDialog";
import Authorize from "../../Authorize";

export default function CanvasIntegration() {
  const [searchParams] = useSearchParams();
  const [, currentSchool, canvasPermission] = useOutletContext();
  const [loading, setLoading] = useState(true);
  const [alertDialogState, setAlertDialogState] = useState(false);
  const [integrationInfo, setIntegrationInfo] = useState(null);
  const methods = useForm();
  const [snackBarOpen, setSnackBarOpen] = useState({
    open: false,
    message: null,
  });

  const getIntegrationInfo = async () => {
    const response = await canvasService.returnIntegrationInfo();

    if (response) {
      setIntegrationInfo(response);
    }

    setLoading(false);
  };

  const onSubmit = async (data) => {
    try {
      const response = await canvasService.updateSchoolCanvasSettings(
        data.canvas_domain,
        data.canvas_sub_account_id,
        data.canvas_root_account_id,
        data.canvas_client_id,
        data.canvas_client_secret,
        data.canvas_redirect_url
      );
      if (response) {
        window.open(
          `${response.canvas_domain}/login/oauth2/auth?client_id=${response.canvas_client_id}&response_type=code&redirect_uri=${response.canvas_redirect_url}`
        );
      }
    } catch (error) {
      setSnackBarOpen({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
  };

  const handleCanvasExport = async () => {
    try {
      const res = await canvasService.exportCsv();

      if (res) {
        setSnackBarOpen({
          message: "Successfully exported to Canvas",
          severity: "success",
          open: true,
        });
      } else {
        setSnackBarOpen({
          message: "Error connecting to Canvas",
          severity: "error",
          open: true,
        });
      }
    } catch (error) {
      setSnackBarOpen({
        message: "Error connecting to Canvas",
        severity: "error",
        open: true,
      });
    }
  };

  const handleCanvasDisconnect = async () => {
    try {
      const res = await canvasService.disconnect();

      if (res.message === "Canvas Integration disconnected") {
        window.location.reload();
      }
    } catch (error) {
      setSnackBarOpen({
        message: "Error disconnecting school",
        severity: "error",
        open: true,
      });
    }
  };

  useEffect(() => {
    if (searchParams.get("success")) {
      setSnackBarOpen({
        open: true,
        message: "Canvas integration successful",
        severity: "success",
      });
    }
  }, [searchParams]);

  useEffect(() => {
    getIntegrationInfo();
  }, []);

  if (!loading && canvasPermission) {
    return (
      <Authorize permission={canvasPermission}>
        <Box sx={container}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid sx={mt35} container>
                <Grid container item sm={10} rowSpacing={3} columnSpacing={2}>
                  <Grid item sm={4}>
                    <ErasableTextField
                      id="canvas_domain"
                      label="Canvas Domain URL"
                      fullWidth
                      isRequired
                      defaultValue={integrationInfo?.canvas_domain}
                      disabled={integrationInfo}
                    />
                  </Grid>

                  <Grid item sm={8} />

                  <Grid item sm={4}>
                    <ErasableTextField
                      id="canvas_sub_account_id"
                      label="Canvas Sub Account SIS ID"
                      fullWidth
                      type="text"
                      defaultValue={currentSchool?.canvas_sub_account_id}
                      disabled={integrationInfo}
                      isRequired
                    />
                  </Grid>

                  <Grid item sm={8} />

                  <Grid item sm={4}>
                    <ErasableTextField
                      id="canvas_root_account_id"
                      label="Canvas Root Account ID"
                      fullWidth
                      type="text"
                      defaultValue={integrationInfo?.canvas_root_account_id}
                      disabled={integrationInfo}
                      isRequired
                    />
                  </Grid>

                  <Grid item sm={8} />

                  <Grid item sm={4}>
                    <ErasableTextField
                      id="canvas_client_id"
                      label="Client ID"
                      fullWidth
                      type="text"
                      defaultValue={integrationInfo?.canvas_client_id}
                      disabled={integrationInfo}
                      isRequired
                    />
                  </Grid>

                  <Grid item sm={6} />

                  <Grid item sm={6}>
                    <ErasableTextField
                      id="canvas_client_secret"
                      label="Client Secret"
                      fullWidth
                      type="text"
                      defaultValue={integrationInfo?.canvas_client_secret}
                      disabled={integrationInfo}
                      isRequired
                    />
                  </Grid>

                  <Grid item sm={6} />

                  <Grid item sm={6}>
                    <ErasableTextField
                      id="canvas_redirect_url"
                      label="Client Redirect URL"
                      fullWidth
                      type="text"
                      defaultValue={integrationInfo?.canvas_redirect_url}
                      disabled={integrationInfo}
                      isRequired
                    />
                  </Grid>

                  <Grid item sm={6} />

                  <Grid item sm={12}>
                    <Stack direction="row">
                      <Button
                        type="submit"
                        sx={mr10}
                        variant="contained"
                        color="primary"
                        disabled={integrationInfo}
                      >
                        {integrationInfo
                          ? "Canvas already connected"
                          : "Save & Connect"}
                      </Button>
                      {integrationInfo && (
                        <>
                          <Button
                            variant="outline"
                            color="primary"
                            sx={mr10}
                            onClick={() => handleCanvasExport()}
                          >
                            Manual Export
                          </Button>
                          <Button
                            variant="outline"
                            color="primary"
                            onClick={() => setAlertDialogState(true)}
                          >
                            Disconnect
                          </Button>
                        </>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </FormProvider>

          <SnackBarNotification
            message={snackBarOpen.message}
            open={snackBarOpen.open}
            severity={snackBarOpen.severity}
            handleClose={() => setSnackBarOpen(false)}
          />

          <AlertDialog
            isOpen={alertDialogState}
            handleClose={() => setAlertDialogState(false)}
            handleConfirm={() => handleCanvasDisconnect()}
          />
        </Box>
      </Authorize>
    );
  }
}
