import axios from "../utils/axios-util";

const endpoint = "/api/canvas";

const auth = async (params) => {
  const { data } = await axios.get(`${endpoint}/request_token`, params);

  return data;
};

const exportCsv = async () => {
  const { data } = await axios.get(`${endpoint}/export`);

  return data;
};

const updateSchoolCanvasSettings = async (
  canvasDomain,
  canvasSubAccountId,
  canvasRootAccountId,
  canvasClientId,
  canvasClientSecret,
  canvasRedirectUrl
) => {
  const { data } = await axios.patch(`${endpoint}/update_canvas_settings`, {
    canvas_domain: canvasDomain,
    canvas_sub_account_id: canvasSubAccountId,
    canvas_root_account_id: canvasRootAccountId,
    canvas_client_id: canvasClientId,
    canvas_client_secret: canvasClientSecret,
    canvas_redirect_url: canvasRedirectUrl,
  });
  if (!data) {
    console.error("Error updating canvas settings.");
    return { response: { data: { message: "Validation Error" } } };
  }

  return data;
};

const disconnect = async () => {
  const { data } = await axios.patch(`${endpoint}/disconnect_school`);
  if (!data) {
    console.error("Error disconnecting school");
    return { response: { data: { message: "Validation Error" } } };
  }

  return data;
};

const returnIntegrationInfo = async () => {
  const { data } = await axios.get(`${endpoint}/integration_info`);

  return data;
};

const checkPermission = async () => {
  const { data } = await axios.get(`${endpoint}/check_permission`);

  return data;
};

export default {
  auth,
  exportCsv,
  returnIntegrationInfo,
  updateSchoolCanvasSettings,
  disconnect,
  checkPermission,
};
