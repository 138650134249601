import React, { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { GlobalStyles } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import globalStyles from "./App.styles";
import theme from "../utils";
import { storedToken, removeToken, parseJwt } from "../utils/constants/auth";
import {
  LoginLayout,
  MainLayout,
  GuardianLayout,
  StudentPortalLayout,
} from "../layouts";
import EntryScreen from "../components/Login/EntryScreen";
import LoginScreen from "../components/Login/LoginScreen";
import ForgotPassword from "../components/Login/ForgotPassword";
import ResetPassword from "../components/Login/ResetPassword";
import ScrollToTop from "./ScrollToTop";
import { SnackbarProvider } from "../context/SnackbarContext";
import SimpleSnackbar from "../components/SimpleSnackbar";
import studentInfoRoutes from "../routes/studentInfoRoutes";
import scheduleRoutes from "../routes/scheduleRoutes";
import topNavRoutes from "../routes/topNavRoutes";
import subjectRoutes from "../routes/subjectRoutes";
import messagesRoutes from "../routes/messagesRoutes";
import notificationsRoutes from "../routes/notificationsRoutes";
import reportCardRoutes from "../routes/reportCardRoutes";
import guardianRoutes from "../routes/guardianRoutes";
import studentPortalRoutes from "../routes/studentPortalRoutes";
import klassesRoutes from "../routes/klassesRoutes";
import { QuickBarProvider } from "../context/QuickBarContext";
import schoolRoutes from "../routes/schoolRoutes";
import staffInfoRoutes from "../routes/staffInfoRoutes";
import reportsRoutes from "../routes/reportsRoutes";
import admissionsRoutes from "../routes/admissionsRoutes";
import Registration from "./Registration";
import staffRoutes from "../routes/staffRoutes";
import { PermissionsProvider } from "../context/PermissionsContext";
import usePermissions from "../hooks/usePermissions";
import { storeCurrentSchoolId } from "../utils/constants/schoolIdStore";
import { SubmittingProvider } from "../context/SubmittingContext";
import AxiosSubmitInterceptor from "../utils/axiosSubmitInterceptor";
import { SchedulerContextProvider } from "../context/SchedulerContext";
import { NavigationProvider } from "../context/NavigationContext";
import NavigationAlertDialog from "../components/NavigationAlertDialog";
import Error401 from "./401";
import { UnsavedChangesProvider } from "../context/UnsavedChangesContext";
import PaymentSuccessful from "./PaymentSuccessful";
import Inquiry from "./Inquiry";
import { FormBuilderContextProvider } from "../components/Admissions/Setup/FormBuilder/FormBuilderContext";
import { CommonProvider } from "../context/CommonContext";
import coursesRoutes from "../routes/coursesRoutes";
import CanvasRedirect from "./CanvasRedirect";

// eslint-disable-next-line consistent-return
function checkRoutePermission(userRole, location) {
  // check permissions for routes based on user role
  if (
    (userRole === "guardian" && !location.pathname.includes("guardian")) ||
    (userRole === "student" && !location.pathname.includes("student")) ||
    (userRole === "teacher" && !location.pathname.includes("school"))
  ) {
    // redirect to back - when the route is not accessible by the current user
    return window.history.back();
  }
}

function PrivateRoute({ children, error }) {
  const userRole = children.props.user.role;
  const location = useLocation();

  checkRoutePermission(userRole, location);

  if (storedToken && !error) {
    return children;
  }

  removeToken();

  const redirectPath = encodeURIComponent(
    `${location.pathname}${location.search}`
  );
  return window.location.replace(`/login?redirect=${redirectPath}`);
}

function App() {
  const [user, setUser] = useState(false);
  const [teacher, setTeacher] = useState(null);
  const [guardianId, setGuardianId] = useState(null);
  const [studentId, setStudentId] = useState(null);
  const [defaultGuardianKidId, setDefaultGuardianKidId] = useState(null);
  const [defaultStudentSchoolId, setDefaultStudentSchoolId] = useState(null);
  const [defaultSchoolSlug, setDefaultSchoolSlug] = useState(false);
  const [error, setError] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (params.school_id) storeCurrentSchoolId(params.school_id);
  }, [params.school_id]);

  if (teacher) {
    // window.pendo.initialize({
    //   visitor: { id: teacher.id },
    //   account: { id: teacher.id },
    // });
  }

  const fetchData = async () => {
    if (storedToken !== undefined) {
      try {
        const decodedToken = parseJwt(storedToken);
        if (decodedToken.user !== null) {
          setUser({
            ...decodedToken.user,
            guardian: decodedToken.guardian,
            student: decodedToken.student,
            teacher: decodedToken.staff,
          });

          // set additional properties based on role
          if (decodedToken.staff) {
            setTeacher(decodedToken.staff);
            setDefaultSchoolSlug(decodedToken.default_school_id);
          } else if (decodedToken.guardian) {
            setGuardianId(decodedToken.guardian.id);
            setDefaultGuardianKidId(decodedToken.default_guardian_kid_id);
          } else if (decodedToken.student) {
            setStudentId(decodedToken.student.id);
            setDefaultStudentSchoolId(decodedToken.default_student_school_id);
          }
        } else {
          setUser(false);
        }
      } catch (e) {
        setError(true);
        removeToken();
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const url = window.location.href.split("?")[0];
  const urlParts = url.split("/");
  const schoolId = urlParts[4];

  const unauthenticatedRoutes = [
    "/registration/token",
    "/login",
    "/forgot-password",
    "/reset-password",
  ];

  // no need permissions for unauthenticatedRoutes, student-portal, parent-portal
  const permissions = url.includes(...unauthenticatedRoutes)
    ? []
    : usePermissions(schoolId, teacher);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={theme}>
        <GlobalStyles styles={globalStyles} />
        <CommonProvider>
          <SnackbarProvider>
            <BrowserRouter>
              <SchedulerContextProvider>
                <UnsavedChangesProvider>
                  <NavigationProvider>
                    <PermissionsProvider permissions={permissions}>
                      <SubmittingProvider>
                        <AxiosSubmitInterceptor>
                          <QuickBarProvider>
                            <DndProvider backend={HTML5Backend}>
                              <ScrollToTop />
                              <Routes>
                                <Route
                                  path="*"
                                  element={<Navigate to="/login" replace />}
                                />
                                <Route element={<LoginLayout />}>
                                  <Route
                                    path="/login"
                                    element={
                                      <LoginScreen
                                        user={user}
                                        setUser={setUser}
                                        defaultSchoolSlug={defaultSchoolSlug}
                                        guardianId={guardianId}
                                        studentId={studentId}
                                        defaultGuardianKidId={
                                          defaultGuardianKidId
                                        }
                                        defaultStudentSchoolId={
                                          defaultStudentSchoolId
                                        }
                                      />
                                    }
                                  />
                                  <Route
                                    path="/school/:school_id/entry-screen"
                                    element={
                                      <PrivateRoute error={error}>
                                        <EntryScreen
                                          teacher={teacher}
                                          user={user}
                                        />
                                      </PrivateRoute>
                                    }
                                  />
                                  <Route
                                    path="/forgot-password"
                                    element={<ForgotPassword />}
                                  />
                                  <Route
                                    path="/payment-successful"
                                    element={<PaymentSuccessful />}
                                  />
                                  <Route
                                    path="/reset-password"
                                    element={<ResetPassword />}
                                  />
                                </Route>
                                <Route path="/401" element={<Error401 />} />

                                <Route
                                  path="/registration/token=:token"
                                  element={
                                    <Registration needAuthorization={false} />
                                  }
                                />
                                <Route
                                  path="/inquiry/token=:token"
                                  element={
                                    <FormBuilderContextProvider>
                                      <Inquiry needAuthorization={false} />
                                    </FormBuilderContextProvider>
                                  }
                                />
                                <Route
                                  element={
                                    <PrivateRoute error={error}>
                                      <MainLayout
                                        teacher={teacher}
                                        user={user}
                                        defaultSchoolSlug={defaultSchoolSlug}
                                      />
                                    </PrivateRoute>
                                  }
                                >
                                  {topNavRoutes}
                                  {reportCardRoutes}
                                  {studentInfoRoutes}
                                  {subjectRoutes}
                                  {scheduleRoutes}
                                  {messagesRoutes}
                                  {notificationsRoutes}
                                  {klassesRoutes}
                                  {schoolRoutes}
                                  {staffInfoRoutes}
                                  {reportsRoutes}
                                  {admissionsRoutes}
                                  {staffRoutes}
                                  {coursesRoutes}
                                </Route>
                                <Route
                                  element={
                                    <PrivateRoute error={error}>
                                      <GuardianLayout
                                        user={user}
                                        defaultGuardianKidId={
                                          defaultGuardianKidId
                                        }
                                      />
                                    </PrivateRoute>
                                  }
                                >
                                  {guardianRoutes}
                                </Route>
                                <Route
                                  element={
                                    <PrivateRoute error={error}>
                                      <StudentPortalLayout
                                        user={user}
                                        defaultStudentSchoolId={
                                          defaultStudentSchoolId
                                        }
                                      />
                                    </PrivateRoute>
                                  }
                                >
                                  {studentPortalRoutes}
                                </Route>
                                <Route
                                  path="/canvas"
                                  element={
                                    <PrivateRoute error={error}>
                                      <CanvasRedirect user={user} />
                                    </PrivateRoute>
                                  }
                                />
                              </Routes>
                            </DndProvider>
                          </QuickBarProvider>
                        </AxiosSubmitInterceptor>
                      </SubmittingProvider>
                    </PermissionsProvider>
                    <NavigationAlertDialog />
                  </NavigationProvider>
                </UnsavedChangesProvider>
              </SchedulerContextProvider>
            </BrowserRouter>
            <SimpleSnackbar />
          </SnackbarProvider>
        </CommonProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

const app = createRoot(document.getElementById("app"));

app.render(<App />);

export default App;
