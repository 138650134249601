import React, { useEffect, useState } from "react";
import { Container, Box, Typography } from "@mui/material";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import { pageHeaderTitle } from "../components/sharedStyles";
import {
  reportCardContainer,
  reportCardHeaderContainer,
} from "../components/ReportCard/ReportCard.styles";
import SchoolTabs from "../components/School/SchoolTabs";
import canvasService from "../service/canvasService";

export default function SchoolProfile() {
  const schoolId = useParams().school_id;
  const [canvasPermission, setCanvasPermission] = useState(null);
  const [, , , , , , , , , , , currentSchool] = useOutletContext();

  const getPermission = async () => {
    const response = await canvasService.checkPermission();
    setCanvasPermission(response);
  };

  useEffect(() => {
    getPermission();
  }, []);

  return (
    <Container maxWidth={false} variant="header">
      <Box sx={reportCardHeaderContainer}>
        <Typography sx={pageHeaderTitle}>School Profile</Typography>
      </Box>
      <Container maxWidth="xl">
        <SchoolTabs schoolId={schoolId} canvasPermission={canvasPermission} />

        <Box sx={reportCardContainer}>
          <Outlet context={[schoolId, currentSchool, canvasPermission]} />
        </Box>
      </Container>
    </Container>
  );
}
