import React from "react";
import { Route } from "react-router-dom";
import SchoolProfile from "../views/SchoolProfile";
import Profile from "../components/School/Profile";
import SecurityGroup from "../components/School/SecurityGroup";
import GradingScales from "../components/School/GradingScales";
import GPA from "../components/School/GPA";
import Settings from "../components/School/Settings";
import Groups from "../components/School/Groups/Groups";
import CustomFields from "../components/School/CustomFields/CustomFields";
import GraduationPlans from "../components/School/GraduationPlans/GraduationPlans";
import GraduationPlan from "../components/School/GraduationPlans/GraduationPlan";
import GraduationRequirement from "../components/School/GraduationPlans/Requirements/PlanRequirement";
import CanvasIntegration from "../components/School/CanvasIntegration/CanvasIntegration";

export default [
  <Route path="/school/:school_id/school" element={<SchoolProfile />} key={0}>
    <Route
      key={1}
      path="/school/:school_id/school/profile"
      element={<Profile />}
    />
    <Route
      key={2}
      path="/school/:school_id/school/security-groups"
      element={<SecurityGroup />}
    />
    <Route
      key={3}
      path="/school/:school_id/school/grading-scales"
      element={<GradingScales />}
    />
    <Route key={3} path="/school/:school_id/school/gpa" element={<GPA />} />
    <Route
      key={4}
      path="/school/:school_id/school/settings"
      element={<Settings />}
    />
    <Route
      key={5}
      path="/school/:school_id/school/custom-fields"
      element={<CustomFields />}
    />
    <Route
      key={6}
      path="/school/:school_id/school/groups"
      element={<Groups />}
    />
    <Route
      key={7}
      path="/school/:school_id/school/graduation-plans"
      element={<GraduationPlans />}
    />
    <Route
      key={8}
      path="/school/:school_id/school/graduation-plans/:id"
      element={<GraduationPlan />}
    />
    <Route
      key={9}
      path="/school/:school_id/school/graduation-plans/:planId/requirements/:id"
      element={<GraduationRequirement />}
    />
    <Route
      key={10}
      path="/school/:school_id/school/canvas-integration"
      element={<CanvasIntegration />}
    />
  </Route>,
];
