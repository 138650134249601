import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import canvasService from "../service/canvasService";
import { getStoredCurrentSchoolId } from "../utils/constants/schoolIdStore";

export default function CanvasRedirect() {
  const [searchParams] = useSearchParams();
  const [integrationInfo, setIntegrationInfo] = React.useState(null);

  const getIntegrationInfo = async () => {
    const response = await canvasService.returnIntegrationInfo(
      getStoredCurrentSchoolId()
    );

    if (response) {
      setIntegrationInfo(response);
    }
  };

  const handleCanvasAuth = async () => {
    try {
      const res = await canvasService.auth({
        params: { code: searchParams.get("code") },
      });

      if (res.access_token) {
        window.location.replace(
          `/school/${getStoredCurrentSchoolId()}/school/canvas-integration?success=true`
        );
      } else {
        window.location.replace(
          `/school/${getStoredCurrentSchoolId()}/canvas-integration?error=true`
        );
      }
    } catch (error) {
      window.location.replace(
        `/school/${getStoredCurrentSchoolId()}/school/canvas-integration?error=true`
      );
    }
  };

  useEffect(() => {
    getIntegrationInfo();
  }, []);

  useEffect(() => {
    handleCanvasAuth();
  }, [integrationInfo]);
}
